.work {

  p {
    font-size: 17px;
    line-height: 1.5;
    margin-left: 50px;
    margin-right: 50px;
    text-align: left;
    clear: both;
  }

  h1 {
    color: var(--carolina-blue);
    text-align: center;
  }

  h2 {
    color: var(--navy);
    text-align: left;
    margin: 20px;
  }

  h3 {
    color: var(--dark-gray);
    font-style: italic;
    text-align: left;
    margin-left: 50px;
    clear: both;
  }

  h4 {
    color: var(--dark-gray);
    text-align: left;
    margin-left: 50px;
    clear: both;
  }

  img {
    max-width: 15%;
    max-height: 15%;
    width: auto;
    height: auto;
    float: left;
    margin-left: 50px;
    margin-bottom: 15px;
  }

  .cisco {
    h3 {
      color: #049fd9;
    }
  }

  .willowtree {
    h3 {
      color: #19B39F;
    }
  }

  .trc {
    h3 {
      color: #b0d254;
    }
  }

}