// home.scss
.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  .home-header {
    padding: 2rem 1rem;

    h1 {
      margin: 0;
      color: var(--carolina-blue);
    }
  }

  .image-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem 2rem;
    width: 100%;
  }

  .image-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: min(66.67vh, 66.67%); // Either 2/3 of viewport height or maintain 3:2 aspect ratio
    overflow: hidden;
    text-decoration: none;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.02);
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;

      p {
        color: white;
        font-size: 1.5rem;
        margin: 0;
      }
    }

    &:hover .overlay {
      opacity: 1;
    }
  }

  .credit {
    padding: 2rem 1rem;
    margin-top: auto;

    p {
      margin: 0;
      font-size: 0.875rem;
      color: var(--dark-gray);
    }

    .icon-link {
      display: inline-flex;
      align-items: center;
      padding: 0.5rem;
      color: var(--carolina-blue);
      transition: color 0.3s ease;

      &:hover {
        color: var(--navy);
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  .home {
    .credit {
      p {
        color: var(--sandstone);
      }

      .icon-link:hover {
        color: var(--sandstone);
      }
    }
  }
}