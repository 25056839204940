// Variables
$navbar-height: 80px;
$navbar-height-scrolled: 60px;
$navbar-padding: 1.25rem;
$transition-speed: 0.3s;
$primary-color: #13294B;
$accent-color: #4B9CD3;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin mobile {
  @media screen and (max-width: 768px) {
    @content;
  }
}

// Base navbar styles
.navbar {
  @include flex-center;
  justify-content: flex-end;
  height: $navbar-height;
  padding: 0 $navbar-padding;
  position: sticky;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(8px);
  transition: all $transition-speed ease;

  @media screen and (min-width: 769px) {
    &.scrolled {
      height: $navbar-height-scrolled;
    }
  }
}

// Navigation menu
.nav-menu {
  @include flex-center;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    position: relative;

    a {
      color: $primary-color;
      text-decoration: none;
      font-weight: 500;
      transition: color $transition-speed ease;

      &:hover {
        color: $accent-color;
      }

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: -4px;
        left: 0;
        background-color: $accent-color;
        transition: width $transition-speed ease;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }
}

// Mobile menu button
.menu-button {
  display: none;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: $primary-color;
  transition: color $transition-speed ease;
  z-index: 102;

  &:hover {
    color: $accent-color;
  }
}

// Mobile styles
@include mobile {
  .menu-button {
    display: block;
  }

  .nav-menu {
    position: fixed;
    top: $navbar-height;
    right: 0;
    width: 100%;
    height: calc(100vh - #{$navbar-height});
    flex-direction: column;
    justify-content: center;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(8px);
    transform: translateX(100%);
    transition: transform $transition-speed ease;

    &.open {
      transform: translateX(0);
    }

    &__item {
      margin: 1rem 0;

      a {
        font-size: 1.25rem;

        &::after {
          display: none;
        }
      }
    }
  }
}

// Dark mode support
@media (prefers-color-scheme: dark) {
  .navbar {
    background: rgba(0, 0, 0, 0.98);
  }

  .nav-menu {
    &__item a {
      color: white;

      &:hover {
        color: $accent-color;
      }
    }
  }

  @include mobile {
    .nav-menu {
      background: rgba(0, 0, 0, 0.98);
    }
  }

  .menu-button {
    color: white;

    &:hover {
      color: $accent-color;
    }
  }
}