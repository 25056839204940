:root {
  --carolina-blue: #4B9CD3;
  --navy: #13294B;
  --secondary-gray: #F8F8F8;
  --tile-teal: #00A5AD;
  --sunburst-yellow: #FFD100;
  --sandstone: #F4E8DD;
  --azalea-pink: #EF426F;
  --davie-green: #C4D600;
  --longleaf-pine: #00594C;
  --dark-gray: #5A5A5A;
  --transition-duration: 0.5s;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.icon-link {
  display: inline-block;
  padding: 10px;
  color: var(--carolina-blue);
  transition: color var(--transition-duration);
}

.icon-link:hover {
  color: var(--sandstone);
}
