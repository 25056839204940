.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--navy);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #4B9CD3;
}

body {
  background-color: #FFF;
  color: #222;
  font-family: -apple-system, Inter, system-ui;
  font-weight: 300;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #000;
    color: #FFF;
  }
  h1 {
    color: var(--carolina-blue);
  }
}

.App-paragraph {
  font-family: -apple-system, Inter, system-ui;
  color: #FFF;
  font-size: calc(10px + 2vmin);
  line-height: 1.5;
  padding: 10px;
  margin: 10px 0;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
