@mixin responsive-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
}

@mixin responsive-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.image-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 1rem;
  @include responsive-container;
  @include responsive-grid;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}


.image-wrapper {
  position: relative;
  margin: 10px;
  width: 100%;
  height: 0;
  padding-bottom: 75%; // 4:3 aspect ratio
  overflow: hidden;
  cursor: pointer;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.02);
  }
}

// Keep existing overlay
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--sandstone);
  text-align: center;
  font-size: 1.5em;
}

.image-wrapper:hover .overlay {
  opacity: 1;
}

// Add new modal styles
.photo-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  img {
    max-height: 90vh;
    max-width: 90vw;
    object-fit: contain;
  }

  &__close {
    position: fixed;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: white;
    padding: 0.5rem;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    border-radius: 50%;

    &:hover {
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

// Keep your existing mini variants
.image-container-mini {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 0 10px;
  max-width: calc(100% - 20px);
}

.image-wrapper-mini {
  position: relative;
  width: 25%;
}

.image-wrapper-mini img {
  width: 100%;
  height: auto;
}

.image-wrapper-mini:hover .overlay {
  opacity: 1;
}

// Single featured image (for Info and Education pages)
.featured-image {
  @include responsive-container;
  margin-bottom: 2rem;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; // 16:9 aspect ratio
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // Modifier for smaller featured images
  &--small {
    max-width: 800px;
  }
}

// Overlay styles
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  p {
    color: white;
    text-align: center;
    padding: 1rem;
    font-size: 1.25rem;
  }
}

.image-wrapper:hover .overlay {
  opacity: 1;
}