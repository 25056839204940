.left-align {
  h2 {
      text-align: left;
      margin-left: 20px;
      font-weight: bold;
  }
  h3 {
    text-align: left;
    margin-left: 20px;
    font-weight: normal;
  }
  p {
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
  }
 img {
    align-content: left;
  }
}